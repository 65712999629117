<template>
  <div class="pt-layout" role="group">
    <Header v-if="!getLoading.status" :logo="LOGO_TIERRA" />
    <main
      :class="['pt-layout__content', { alternative: !checkControls.showDefault }]"
      aria-live="polite"
      aria-atomic="true"
      aria-relevant="additions"
    >
      <Loading v-if="getLoading.status" system />
      <slot />
    </main>
  </div>
</template>

<script>
import { LOGO_TIERRA } from '~/utils/enums';
import Loading from '@/components/Loading';

export default {
  name: 'PtRegisterLayout',
  components: {
    Loading
  },
  setup() {
    useMonitoring();
    useAuthentication();
    useVerificationEmail();

    const store = useStore();
    const route = useRoute();

    const getLoading = computed(() => store.state.auth.loading);

    const checkControls = computed(() => ({ showDefault: route.path.includes('cxtierra') }));

    return {
      LOGO_TIERRA,
      getLoading,
      checkControls
    };
  }
};
</script>

<style lang="scss" scoped>
.pt-layout {
  @apply min-h-screen flex flex-col justify-center bg-orange-pale;
  &__content {
    padding-top: $header-height;
    min-height: calc(100vh - #{$footer-height} - #{$footer-height-aux});
  }
}
</style>
