export const useVerificationEmail = () => {
  const store = useStore();
  const state = reactive({
    loading: false,
    sent: false,
    messageData: {}
  });
  const { loading, sent, messageData } = toRefs(state);
  const sendEmailVerification = async user => {
    try {
      loading.value = true;
      await store
        .dispatch('profile/resendRegisterVerificationByEmail', {
          pathParam: 'resendRegisterVerificationByEmail',
          payload: {
            email: user
          }
        })
        .then(() => {
          loading.value = false;
          sent.value = true;
        });
    } catch (err) {
      messageData.value = {
        icon: 'pt-exclamation-circle',
        title: this.$t('forms.errors.throwError'),
        error: true
      };
    } finally {
      loading.value = false;
    }
  };
  return {
    messageData,
    sendEmailVerification
  };
};
